"use client";
import { Render } from "@measured/puck";
import config from "@components/puck/page-builder/config";
import React from "react";
import { Page } from "@models/models";

export default function PageWrapper({ page }: { page: Page }) {
  return (
    <div>
      <Render
        config={config}
        data={structuredClone(page).editor_content as any}
      />
    </div>
  );
}
